<template>
  <div class="correlationTest">
    <div class="topp">
      <p>年龄</p>
      <el-select
          class="pp"
          v-model="postData.S_OP_Json.S_OP_VF_Age"
          allow-create
          collapse-tags
          default-first-option
          filterable
          placeholder="请选择或输入">
        <el-option
            v-for="item in ageLsit"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <p>岁</p>
      <el-button @click="tb" class="tbsj" icon="el-icon-refresh" type="primary">同步数据</el-button>
    </div>
    <div class="bz">
      <p>主诉</p>
      <el-input
          class="bzsr"
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="postData.S_OP_Json.S_OP_VF_Say">
      </el-input>
    </div>
    <div style="text-align: left;padding-top: .4rem">屈光度</div>
    <div class="srA">
      <div>
        <ul class="content-sr w8 padding-20">
          <li></li>
          <li>球镜</li>
          <li>柱镜</li>
          <li>轴向</li>
          <li>矫正视力</li>
          <li>瞳距</li>
        </ul>
        <ul class="content-sr w8">
          <li>OD</li>
          <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_VF_DS_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_VF_DS_OD, 'S_OP_VF_DS_OD')" v-model="postData.S_OP_Json.S_OP_VF_DS_OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_VF_DC_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_VF_DC_OD, 'S_OP_VF_DC_OD')" v-model="postData.S_OP_Json.S_OP_VF_DC_OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_VF_X_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_VF_X_OD, 'S_OP_VF_X_OD')" v-model="postData.S_OP_Json.S_OP_VF_X_OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_VF_VA_OD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_VF_VA_OD, 'S_OP_VF_VA_OD')" v-model="postData.S_OP_Json.S_OP_VF_VA_OD" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_VF_PD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_VF_PD, 'S_OP_VF_PD')" v-model="postData.S_OP_Json.S_OP_VF_PD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr w8 padding-20">
          <li>OS</li>
          <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_VF_DS_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_VF_DS_OS, 'S_OP_VF_DS_OS')" v-model="postData.S_OP_Json.S_OP_VF_DS_OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_VF_DC_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_VF_DC_OS, 'S_OP_VF_DC_OS')" v-model="postData.S_OP_Json.S_OP_VF_DC_OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_VF_X_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_VF_X_OS, 'S_OP_VF_X_OS')" v-model="postData.S_OP_Json.S_OP_VF_X_OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_VF_VA_OS'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_VF_VA_OS, 'S_OP_VF_VA_OS')" v-model="postData.S_OP_Json.S_OP_VF_VA_OS" placeholder="请输入内容"></el-input></li>
          <li><el-input @keydown.native="keyd($event)" :class="[srval==='S_OP_VF_PD'?'xz':'']" @focus="getgb(postData.S_OP_Json.S_OP_VF_PD, 'S_OP_VF_PD')" v-model="postData.S_OP_Json.S_OP_VF_PD" placeholder="请输入内容"></el-input></li>
        </ul>
        <ul class="content-sr padding-20">
          <li></li>
          <li>远</li>
          <li>近</li>
          <li></li>
          <li>主导眼</li>
        </ul>
        <ul class="content-sr">
          <li>Worth4</li>
          <li>
            <el-select
                class="srr"
                v-model="postData.S_OP_Json.S_OP_VF_Worth4_Far"
                filterable
                allow-create
                collapse-tags
                default-first-option
                placeholder="请选择或输入">
              <el-option
                  v-for="item in w4"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li>
            <el-select
                class="srr"
                v-model="postData.S_OP_Json.S_OP_VF_Worth4_Near"
                filterable
                allow-create
                collapse-tags
                default-first-option
                placeholder="请选择或输入">
              <el-option
                  v-for="item in w4"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
          <li></li>
          <li>
            <el-select
                class="srr"
                v-model="postData.S_OP_Json.S_OP_VF_leadingEye"
                allow-create
                collapse-tags
                default-first-option
                filterable
                placeholder="请选择或输入">
              <el-option
                  v-for="item in zdy"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
        <ul class="content-sr padding-20">
          <li>立体视</li>
          <li>
            <el-select
                class="srr"
                v-model="postData.S_OP_Json.S_OP_VF_Stereopsis_Far"
                filterable
                allow-create
                collapse-tags
                default-first-option
                placeholder="请选择或输入">
              <el-option
                  v-for="item in lts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            "
          </li>
          <li>
            <el-select
                class="srr"
                v-model="postData.S_OP_Json.S_OP_VF_Stereopsis_Near"
                filterable
                allow-create
                collapse-tags
                default-first-option
                placeholder="请选择或输入">
              <el-option
                  v-for="item in lts"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            "
          </li>
          <li>
            <el-select
                class="srr"
                v-model="postData.S_OP_Json.S_OP_VF_Stereopsis_Tool"
                allow-create
                collapse-tags
                default-first-option
                placeholder="请选择或输入">
              <el-option
                  v-for="item in lastsp"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </li>
        </ul>
      </div>
    </div>
    <div class="bz">
      <p>备注</p>
      <el-input
          class="bzsr"
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="postData.S_OP_Json.S_OP_VF_Remark">
      </el-input>
    </div>

    <KEYS v-if="showkeys" :keyval="keyval" @changeval="changeval"></KEYS>
  </div>
</template>

<script>
export default {
  name: "correlationTest",
  data() {
    return {
      showdrsb: false,
      showkeys: false,
      issr: false,
      srval: '',
      keyval: '',
      iszf: 0,


      postData: {S_OP_Json: {}},
      textarea: '',
      w4: [
        {
          value: '2',
          label: '2'
        },
        {
          value: '3',
          label: '3'
        },
        {
          value: '4',
          label: '4'
        },
        {
          value: '5',
          label: '5'
        },
        {
          value: ' ',
          label: ' '
        }
      ],
      zdy: [
        {
          value: '右',
          label: '右'
        },
        {
          value: '左',
          label: '左'
        },
        {
          value: '交替',
          label: '交替'
        },
        {
          value: ' ',
          label: ' '
        }
      ],
      lts: [
        {
          value: '有',
          label: '有'
        },
        {
          value: '无',
          label: '无'
        },
        {
          value: '40',
          label: '40'
        },
        {
          value: '60',
          label: '60'
        },
        {
          value: '80',
          label: '80'
        },
        {
          value: '100',
          label: '100'
        },
        {
          value: '200',
          label: '200'
        },
        {
          value: '400',
          label: '400'
        },
        {
          value: '600',
          label: '600'
        },
        {
          value: '800',
          label: '800'
        }
      ],
      lastsp: [
        {
          value: '颜氏立体图',
          label: '颜氏立体图'
        },
        {
          value: 'Titmus',
          label: 'Titmus'
        }
      ],
      ageLsit: [{
        value: 1,
        label: 1
      }],
      age: 1
    }
  },
  watch: {
    postData: {
      handler(n, o) {
        this.$store.commit('eyeTest/upViewFnTest', n)
      },
      deep: true
      // immediate: true
    }
  },
  created() {
    document.addEventListener('click', this.getXY, false)
    let arr = []
    for (let i = 1; i < 120; i++) {
      arr.push({
        value: i,
        label: i
      })
    }
    this.ageLsit = arr
    this.postData = this.$store.state.eyeTest.ViewFnTest
  },
  methods: {
    tb() {
      let datas = this.$store.state.eyeTest.refractiveData.S_OP_Json

      this.$set(this.postData.S_OP_Json, 'S_OP_VF_DS_OD', datas.S_OP_R_SS_DSOD)
      this.$set(this.postData.S_OP_Json, 'S_OP_VF_DC_OD', datas.S_OP_R_SS_DCOD)
      this.$set(this.postData.S_OP_Json, 'S_OP_VF_X_OD', datas.S_OP_R_SS_XOD)
      this.$set(this.postData.S_OP_Json, 'S_OP_VF_PD', datas.S_OP_R_SS_PDOD)

      this.$set(this.postData.S_OP_Json, 'S_OP_VF_DS_OS', datas.S_OP_R_SS_DSOS)
      this.$set(this.postData.S_OP_Json, 'S_OP_VF_DC_OS', datas.S_OP_R_SS_DCOS)
      this.$set(this.postData.S_OP_Json, 'S_OP_VF_X_OS', datas.S_OP_R_SS_XOS)
      this.$set(this.postData.S_OP_Json, 'S_OP_VF_PD', datas.S_OP_R_SS_PDOD)

      this.$set(this.postData.S_OP_Json, 'S_OP_VF_leadingEye', datas.S_OP_R_SS_LeadingEye)

      this.$set(this.postData.S_OP_Json, 'S_OP_VF_Worth4_Far', datas.S_OP_R_SS_Worth4Far)
      this.$set(this.postData.S_OP_Json, 'S_OP_VF_Worth4_Near', datas.S_OP_R_SS_Worth4Near)
      this.$set(this.postData.S_OP_Json, 'S_OP_VF_Stereopsis_Far', datas.S_OP_R_SS_Stereopsis4Far)
      this.$set(this.postData.S_OP_Json, 'S_OP_VF_Stereopsis_Near', datas.S_OP_R_SS_Stereopsis4Near)
      this.$set(this.postData.S_OP_Json, 'S_OP_VF_Stereopsis_Tool', datas.S_OP_R_SS_EyeBalanceTool)
    },


    keyd(e) {
      e.returnValue= false
    },

    srjc(val, min, max) {
      if (val) {
        if (val.indexOf('^') > -1) return false
        if (isNaN(Number(val))) return true
        if (Number(val) > Number(max)) return true
        if (Number(val) < Number(min)) return true
        return false
      }
      return false
    },

    getgb(str, key, iszf) {
      // console.log(e)
      // document.activeElement.blur();
      this.keyval = str
      this.srval = key
      this.issr = true
      this.iszf = iszf
    },

    getXY(e) {
      if (this.issr) {
        this.$store.commit('isShow/upkeyh', e.y)
        this.showkeys = true
        this.issr = false
      }else  {
        this.showkeys = false
        this.srval = ''
      }

    },

    changeval(e) {
      this.keyval = e
      this.$set(this.postData.S_OP_Json, this.srval, e)
      if (this.postData.S_OP_Json[this.srval] && !isNaN(Number(this.postData.S_OP_Json[this.srval])) && this.iszf) {
        if (this.postData.S_OP_Json[this.srval] && Number(this.postData.S_OP_Json[this.srval]) > 0 && this.postData.S_OP_Json[this.srval].indexOf('+') < 0  && this.postData.S_OP_Json[this.srval].indexOf('-') < 0) {
          this.$set(this.postData.S_OP_Json, this.srval, '+'+e)
        }
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.getXY)
  },
  components: {
    KEYS: () => import('@/components/keyboard/keyHome')
  }
}
</script>

<style scoped lang="scss">
  .correlationTest {width: 60vw;}
  .padding-20{padding-top: 15px;}
  .srA {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    .content-sr {
      display: flex;
      justify-content: start;
      align-items: center;
      li {
        width: 8vw;
        margin-right: 0.4rem;
        display: flex;
        align-items: center;
        justify-content: center;
        input {
          display: block;
          width: 100%;
          outline: none;
          border: 1px solid #000000;
          border-radius: 5px;
          padding: 5px 0;
          text-align: center;
        }
        .syph {
          position: absolute;
          top: -1.4em;
          text-align: center;
          width: 100%;
        }
      }
      li:nth-child(1) {
        width: 4vw;
        justify-content: start;
        margin-right: 5px;
      }
    }
    .w8 { li {width: 7vw;} }
    .tj {
      input {
        display: block;
        width: 100%;
        outline: none;
        border: 1px solid #000000;
        border-radius: 5px;
        padding: 5px 0;
        text-align: center;
        margin-top: 21px;
      }
    }
    .last {
      margin-left: 50px;
      margin-top: 32px;
    }
  }
  ::v-deep  .srA li input:hover{background: #e8e6e6}
  ::v-deep .xz input {background: #e8e6e6}
  .topp {
    display: flex;
    align-items: center;
    justify-content: start;
    .pp {width: 80px;margin: 0 10px;}
    .tbsj {margin-left: 50px;}
  }
  .bz {
    padding-top: 2vh;
    display: flex;
    align-items: center;
    .bzsr {width: 44vw;margin-left: 10px;}
  }
</style>
